@import "../../variables";

.menu-top-bar {
  position: absolute;
  top: 0;
  padding: $paddingSm 16px;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  button {
    height: 100%;
  }

  .menu-btn{
    border: 1px solid #d0d5dd;
    border-radius: $borderRadiusSm;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    color: $gray500;

    @media (max-width: 567.92px) {
      height: 32px;
    }

    &:hover {
      color: $gray700;
    }
  }

  .btn-default {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadiusSm !important;

    img {
      height: 20px;
    }
  }

  #title-tools-top-bar {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 12px;

    svg{
      color: $gray500;
    }

    &:hover {
      > span,
      button, svg {
        color: $gray700 !important;
      }
    }

    span {
      font-size: 14px;
      font-weight: 500;
    }
    button {
      position: relative;
      .dropdown-tools {
        position: absolute;
        z-index: -1;
        width: 230px;
        height: auto;
        right: -135px;
        top: 32px;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid $gray100;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 2px 0px $grayType3;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;

        @media (max-width: 991.92px) {
          right: 0;
        }
        ul {
          list-style: none;
          margin: 0;
          width: 100%;
          text-align: left;
          padding: 8px;
          li {
            border-radius: 6px;
            color: $gray700;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 15px;
            font-family: "Inter", sans-serif;
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            svg {
              transition: all 0.3s ease;
            }
            &:hover {
              background: $gray100;
              color: $gray900;
              .export-as-pdf,
              .rename-conversation {
                stroke: $greenType1;
              }
              .clear-conversation,
              .delete-conversation {
                stroke: $redType1;
              }
            }
          }
        }
      }
      .dropdown-tools.show {
        opacity: 1;
        transform: translateY(0);
        z-index: 999;
      }
    }
  }
}
