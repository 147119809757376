
.st0{font-family:'FootlightMTLight';}
.st1{font-size:83.0285px;}
.st2{fill:gray;}

#container-page-not-found svg{
  width: 500px;
    height: 400px;
    text-align: center;
    fill: rgb(19 175 157 / 90%);

    @media (max-width: 575.98px) {
      width: 350px;
    }
}
#container-page-not-found path#XMLID_5_ {
   
    fill: rgb(19 175 157 / 90%);
    filter: url(#blurFilter4);
}
#container-page-not-found path#XMLID_11_ ,path#XMLID_2_ {
    fill: rgb(19 175 157 / 90%);
}
#container-page-not-found .circle{
  animation: out 2s infinite ease-out;
  fill: rgb(19 175 157 / 90%);
}

#container-page-not-found{
  text-align:center;
}
#container-page-not-found .message-page-not-found{
	color:rgb(19 175 157 / 90%);
}
#container-page-not-found .message-page-not-found:after{
	content:"]";
}
#container-page-not-found .message-page-not-found:before{
	content:"[";
}

#container-page-not-found .message-page-not-found:after, #container-page-not-found .message-page-not-found:before {
  
  color: rgb(19 175 157 / 90%);
  font-size: 20px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: opacity;
          animation-name: opacity;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          margin:0 50px;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes out {
  0% {r:1;  opacity: 0.9 ;}
  25%{r:5;  opacity: 0.3 ;}
  50%{r:10; opacity: 0.2 ;}
  75%{r:15;opacity:0.1;}
 	100% {r:20;opacity:0;}
}

#container-page-not-found .button-page-not-found{
  border: 1px solid rgb(19 175 157 / 90%);
  padding: 12px 45px; 
}