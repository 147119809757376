.markdown ol {
  counter-reset: item;
}

.markdown ul li {
  display: block;
  margin: 0;
  position: relative;
}

.markdown {
  @media (max-width: 767px) {
    p{
      font-size: 14px;
    }
  }
}

.markdown ul li:before {
  content: "•";
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: -1rem;
  position: absolute;
}

.markdown {
  max-width: 100%;
}

.markdown h1,
.markdown h2 {
  font-weight: 600;
}

.markdown h2 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.markdown h3 {
  font-weight: 600;
}

.markdown h3,
.markdown h4 {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.markdown h4 {
  font-weight: 400;
}

.markdown h5 {
  font-weight: 600;
}

.markdown blockquote {
  --tw-border-opacity: 1;
  border-color: rgba(142, 142, 160, var(--tw-border-opacity));
  border-left-width: 2px;
  line-height: 1rem;
  padding-left: 1rem;
}

.markdown ol,
.markdown ul {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.markdown ol li,
.markdown ol li > p,
.markdown ol ol,
.markdown ol ul,
.markdown ul li,
.markdown ul li > p,
.markdown ul ol,
.markdown ul ul {
  margin: 0;
}

.markdown table {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-collapse: separate;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%;
  margin-bottom: 1rem;
}

.markdown th {
  background-color: rgba(236, 236, 241, 0.2);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: 0.25rem 0.75rem;
}

.markdown th:first-child {
  border-top-left-radius: 0.375rem;
}

.markdown th:last-child {
  border-right-width: 1px;
  border-top-right-radius: 0.375rem;
}

.markdown td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding: 0.25rem 0.75rem;
}

.markdown td:last-child {
  border-right-width: 1px;
}

.markdown tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.375rem;
}

.markdown tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.375rem;
}

.markdown a {
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.markdown code {
  white-space: break-spaces !important;
}

.markdown li ~ li {
  margin-top: 1rem;
}

.markdown img {
  width: 100%;
  height: auto;
  @media screen and (min-width: 768px) {
    width: 512px;
    height: 512px;
  }
}
