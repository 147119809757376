@import "../../variables";

.main-layout {
  padding: 60px 0px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-grow: 1;
  flex-basis: 0;
  max-width: calc(100% - 270px);
  position: relative;
  transition: all 0.3s;

  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    margin-right: -270px;
  }
  @media (max-width: 768.98px) {
    max-width: 100%;
    height: calc(100vh - 80px);
  }

  &.maximize {
    max-width: calc(100% - 70px);

    @media (max-width: 992px) {
      max-width: calc(100% - 50px);
    }
    @media (max-width: 768.98px) {
      max-width: calc(100%);
    }
  }

  h1 {
    margin-bottom: 30px;
  }

  .modal-no-credits {
    width: 100% !important;
    max-width: 880px !important;

    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 38px;
      text-align: left;

      @media (max-width: 567.92px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    label {
      font-size: 11px;
      font-weight: 600;
      line-height: 11px;
      text-align: left;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;

      @media (max-width: 567.92px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    ul {
      li {
        color: $gray900;
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: 567.92px) {
          font-size: 14px;
        }
      }
    }
  }
}
