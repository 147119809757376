//
// Google font - Public Sans
//

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

//
// Premium font - Cerebri Sans
//

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../../../fonts/cerebrisans-light.eot");
  src: local("Cerebri-sans Light"),
    url("../../../fonts/cerebrisans-light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../../../fonts/cerebrisans-regular.eot");
  src: local("Cerebri-sans Regular"),
    url("../../../fonts/cerebrisans-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../../../fonts/cerebrisans-medium.eot");
  src: local("Cerebri-sans Medium"),
    url("../../../fonts/cerebrisans-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../../../fonts/cerebrisans-semibold.eot");
  src: local("Cerebri-sans Semibold"),
    url("../../../fonts/cerebrisans-semibold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../../../fonts/cerebrisans-bold.eot");
  src: local("Cerebri-sans Bold"),
    url("../../../fonts/cerebrisans-bold.woff") format("woff");
  font-weight: 700;
}
