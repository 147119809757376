@import "../../variables.scss";

.main-landing-page {
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  .container-landing {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }

    .img-container {
      width: 100%;
      height: 100%;
      box-shadow: 0px 3.99px 6.52px rgba(0, 0, 0, 0.0157),
        0px 18.87px 25.48px rgba(0, 0, 0, 0.0243),
        0px 49px 80px rgba(0, 0, 0, 0.04);
    }

    .elipse {
      border-radius: 50%;
      border: 1px solid $gray200;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
    }

    .card-assistants-landing-page {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      gap: 16px;
      border-radius: 16px;
      height: 100%;
      width: 100%;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 991.92px) {
          width: 60px;
          height: 60px;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: 100%;

        span {
          font-size: 7.46px;
          font-weight: 500;
          line-height: 9.94px;
          letter-spacing: -0.01em;
          text-align: left;
        }

        h2 {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-align: left;
          color: #101828;

          @media (max-width: 991.92px) {
            font-size: 9.94px;
            font-weight: 500;
            line-height: 14.92px;
            letter-spacing: -0.01em;
            text-align: center;
          }
        }

        img {
          width: 16px;
          height: 16px;
          border-radius: 4.3px;
          object-fit: cover;

          @media (max-width: 991.92px) {
            width: 9.94px;
            height: 9.94px;
          }
        }

        p {
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.01em;
          color: #667085;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;

          @media (max-width: 991.92px) {
            font-size: 7.46px;
            font-weight: 500;
            line-height: 11.19px;
            letter-spacing: -0.01em;
            text-align: center;
          }
        }
      }

      .sizeIconLike {
        width: 16px !important;
        height: 16px !important;
      }
    }

    .card-assistants-new-layout {
      background: #ffffff !important;

      span {
        font-size: 12px;
      }
    }

  }
}
.footer-landing-page {
  span {
    color: $gray900;
  }

  ul {
    li {
      a, button {
        color: $gray600 !important;
      }
    }
  }
}
