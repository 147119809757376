/* @font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-Light.woff") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-Regular.woff") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-Medium.woff") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-SemiBold.woff") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-Bold.woff") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./assets/font-cerebri/CerebriSans-Italic.woff") format("opentype");
  font-style: italic;
} */

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100 900;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcCm3FwrK3iLTcvnUwQT9g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcCo3FwrK3iLTcvvYwYL8g.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

body,
input,
textarea,
button, p, span, label, h1, h2, h3, h4, h5, h6, div, ul, li {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.request-message {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.shadow-left-bar {
  box-shadow: 1.5px 1px 6px rgba(0, 0, 0, 0.12);
}

.token-box {
  border: 1px solid #44484ead;
}