@import "../../variables";

.token-supply-container {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  gap: 58px;
  width: 100%;
  height: 100%;

  .distribution-bar {
    display: flex;
    width: 100%;
    max-width: 600px;
    height: 62px;
    overflow: hidden;

    .bar-section {
      height: 100%;
      border-radius: 16px;

      &.incinerated {
        flex: 91%; // Proporção de espaço
        background-color: $gray900; // Azul escuro
      }

      &.treasure {
        flex: 6%; // Proporção de espaço
        background-color: $gray300; // Cinza
      }

      &.founder {
        flex: 3%; // Proporção de espaço
        background-color: $gray100; // Cinza claro
      }
    }
  }

  .token-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 991.92px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .token-amount {
      font-size: 32px;
      font-weight: 600;
      color: $gray900;
    }

    .token-label {
      font-size: 14px;
      color: $gray900;
      text-transform: uppercase;
      margin-top: 4px;
    }

    .legend {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-top: 16px;

      .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        color: $gray900;

        .legend-color {
          width: 12px;
          height: 12px;
          border-radius: 50%;

          &.incinerated {
            background-color: $gray900;
          }

          &.treasure {
            background-color: $gray300;
          }

          &.founder {
            background-color: $gray100;
          }
        }
      }
    }
  }
}
