//
// _table.scss
//

.table {
  --bs-table-border-color: #ececec;
  th {
    font-weight: $font-weight-bold;
  }
  &.table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// body[data-layout-mode="dark"] {
//   // table
//   .table {
//     border-color: lighten($gray-dark-200, 5%);
//     color: #fff;
//   }

//   .table-bordered {
//     border-color: #343442;
//   }

//   .table > :not(:last-child) > :last-child > * {
//     border-bottom-color: $gray-dark-300;
//   }
// }
