//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

//
.font-tabs-assistants {
  .swiper-slide {
    cursor: pointer;

    div {
      border-radius: 10px 10px 0 10px;
      @media (max-width: 576px) {
        border-radius: 7px 7px 0 7px;
      }

      img {
        width: 18px;
        height: 18px;
      }
    }

    a p {
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .icon-tab-assistants {
    font-size: 19px !important;
  }

  .active-tab {
    background: none !important;
    color: #000 !important;
    p {
      border-bottom: 1px solid #000 !important;
    }
  }
}
