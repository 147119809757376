@import "../../variables";

.input-main-chat {
  width: 1100px;
  max-width: calc(100% - 30px);
  border-radius: $borderRadiusMd;
  position: relative;
  z-index: 13;

  textarea {
    height: 60px;
    background: transparent;
    color: $gray900;
    outline: none;
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $gray400;
    }
  }

  .input-main-chat-mobile {
    display: flex;
    align-items: center;

    > input {
      height: 24px;
      background: transparent;
      padding: 0;
      color: $gray900;
      outline: none;
      width: 100%;
      border: none;
    }
  }

  .group-buttons {
    display: flex;
    gap: 12px;

    @media (max-width: 400px) {
      flex-wrap: wrap;
    }

    .buttons-change-type-chat {
      display: flex;
      align-items: center;
      padding: 3px;
      height: 40px;
      background-color: #fff;
      border-radius: 8px;
      gap: 2px;

      button {
        border-radius: 8px !important;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray500;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover,
        &.active {
          background-color: $colorType1;
          color: $gray700;
        }
      }
    }

    .selectors {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .change-settings-mobile {
        color: $gray500;
        cursor: pointer;

        &:hover {
          color: $gray700;
        }
      }

      .media-send-actions-mobile {
        @media (max-width: 400px) {
          justify-content: space-between;
        }
      }

      .change-assistant-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 11px;
        cursor: pointer;

        .name-assistant {
          color: $gray700;
          font-size: 16px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 450px) {
            width: 100%;
            max-width: 250px;
          }
        }
        .wrapper-avatar {
          min-width: 16px;
          min-height: 16px;
          max-width: 16px;
          max-height: 16px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .wrapper-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
            color: $gray700;
          }
        }
      }
      .modal-content-global {
        overflow: hidden !important;
      }
      .modal-children-select-assistant {
        height: calc(100vh - 80px);
        width: 750px !important;

        ul {
          max-height: 50vh;
          overflow: auto;
          padding: 0 0 10px 0;

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            min-height: 44px;
          }
        }

        h3 {
          font-size: 20px;
          line-height: 32px;
          text-align: left;
          color: $gray900;
        }

        .list-assistants-modal {
          position: relative;

          span {
            text-transform: capitalize;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            width: 90%;
          }

          &.active {
            background: $gray100;
          }

          &:hover {
            background-color: $gray100;
          }

          .active-icon {
            position: absolute;
            right: 10px; /* Ajuste conforme necessário */
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;
            transition: opacity 0.2s;
          }
        }
      }

      .modal-children-select-model {
        height: calc(100vh - 80px);
        width: 750px !important;

        .list-group-model{
          &::-webkit-scrollbar {
            display: none;
          }
        }

        h3 {
          font-size: 20px;
          line-height: 32px;
          text-align: left;
          color: #101828;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            display: flex;
            align-items: center;
            min-height: 40px;
            cursor: pointer;
            border-radius: 8px;
            padding: 0px 12px;

            &:hover {
              background-color: $gray100;
            }

            &.active {
              background-color: $gray100;
            }
          }
        }

        h4 {
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          color: $gray900;
        }

        .group-model {
          height: 56px;
          cursor: pointer;
          border: 1px solid $gray200;
          border-radius: 12px;

          img {
            background: transparent;
          }

          &:hover {
            border: 1px solid $gray300 !important;
          }

          &.active {
            border: none !important;
          }
        }

        .select-model-list {
          overflow: auto;
          max-height: 290px;
          padding: 10px 12px;

          li {
            color: $gray500;

            &:hover {
              color: $gray700;
            }

            &.active {
              background: #fff;
              color: $gray700;
            }
          }
        }

        .details-model {
          ul {
            li {
              border: 1px solid $gray300;
              padding: 12px;
              overflow-x: hidden;

              span {
                font-size: 14px;
                color: $gray900;

                @media (max-width: 768px) {
                  font-size: 13px;
                }
              }

              p {
                margin: 0;
                font-size: 13px;
                text-transform: capitalize;
                color: $gray500;
                width: 100%;

                @media (max-width: 768px) {
                  font-size: 12px;
                }
              }

              &:hover {
                background: none;
                cursor: unset;
              }
            }
          }
        }
      }
    }

    .action-buttons {
      gap: 12px;
      display: flex;
      align-items: center;
      margin-left: auto;

      @media (max-width: 768px) {
        order: 1;
        width: 100%;

        button {
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
        }
      }
    }
  }

  .tab-button {
    padding: 8px 10px 8px 8px;
    border-radius: $borderRadiusSm;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: $gray500;

    &:hover {
      color: $gray700;
    }

    &.active {
      background: $gray100 !important;
      color: $gray700;
    }
  }
}

.opened-chat {
  box-shadow: 0 0 24px 16px #fff;
}

.ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.btn-actions {
  height: 40px;
  width: 40px;
  border-radius: 8px !important;
}
