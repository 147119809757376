@import "../../variables";

.chat-group {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 1100px;
  transition: all .5s;
  overflow: hidden;
  height: 0;
  max-height: 0;
  gap: $gapMd;

  &.active{
    height: unset;
    max-height: 100%;
    overflow: auto;
  }

  .chat-main{
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    position: relative;

    // &::after{
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100px;
    //   background: linear-gradient(180deg, transparent, white);
    // }
  }
}