.chat-conversation {
  flex: 1;

  .conversation-list {
    width: 100%;
    margin: 0;
    overflow: hidden; /* Padrão para dispositivos maiores */
  }
}

/* Media query para mobile */
@media (max-width: 768px) {
  .chat-conversation {
    .conversation-list {
      overflow-x: auto; /* Overflow para rolar verticalmente no mobile */
      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    /* Estilizando a barra de rolagem */
    .conversation-list::-webkit-scrollbar {
      width: 2px; /* Largura da barra de rolagem */
    }

    .conversation-list::-webkit-scrollbar-track {
      background: transparent; /* Fundo da barra de rolagem */
    }

    .conversation-list::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.309); /* Cor do "polegar" da barra */
      border-radius: 10px; /* Deixando a barra mais arredondada */
    }
  }
}
