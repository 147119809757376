@import "../../variables";

.timeline {
  position: relative;
  padding: 16px;
  max-width: 1068px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 90%;
    background: linear-gradient(180deg, #E4E7EC 0%, #E4E7EC 88%, #fff 100%);

    @media screen and (max-width: 567.92px) {
      left: 32px;
      top: 80px;
    }
}

  .timeline-item {
    position: relative;
    margin: 40px 0;
    padding-left: 65%;
    text-align: left;

    @media screen and (max-width: 567.92px) {
        padding-left: 60px;
        margin: 20px 0;
    }

    &:nth-child(even) {
      padding-left: 0;
      padding-right: 65%;
      text-align: right;

      @media screen and (max-width: 567.92px) {
        padding-right: 0%;
        padding-left: 60px;
        text-align: left;
      }

      .timeline-date {
        justify-content: flex-end;
        @media screen and (max-width: 567.92px) {
            justify-content: flex-start;
        }
      }
    }

    &:nth-child(odd) {
      .timeline-date {
        justify-content: flex-start; // Alinha para a esquerda
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: $gray200;
      border-radius: 50%;
      border: 2px solid #fff;
      z-index: 1;

      @media screen and (max-width: 567.92px) {
        left: 16px;
      }
    }

    &:first-child {
        margin-top: 0; // Remove o margin-top do primeiro item
      }

    &:first-child::before {
      width: 42px;
      height: 42px;

      @media screen and (max-width: 567.92px) {
        width: 32px;
        height: 32px;
      }
    }
    &:first-child::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      z-index: 2;

      @media screen and (max-width: 567.92px) {
        left: 16px;
        width: 16px;
        height: 16px;
      }
    }

    .timeline-date {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .timeline-date-start,
      .timeline-date-end {
        background: $gray100;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 600;
        color: $gray800;
      }
    }

    .timeline-content {
      .timeline-title {
        font-size: 24px;
        margin: 0 0 10px;
        color: $gray900;

        @media screen and (max-width: 767.92px) {
          font-size: 20px;
        }
      }

      .timeline-description {
        font-size: 16px;
        color: $gray700;
        margin: 0;

        @media screen and (max-width: 767.92px) {
          font-size: 14px;
        }
      }
    }
  }
}
