.page-settings {
  padding: 60px 20px 20px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100vh;
  flex-grow: 1;
  flex-basis: 0;
  max-width: calc(100%);
  position: relative;
  transition: all 0.3s;
  overflow-x: hidden;
  gap: 32px;

  @media (max-width: 768.98px) {
    height: calc(100vh - 80px);
  }

  .divider {
    border: 1px solid rgba($gray300, 0.4);
    height: 1px;
  }

  .page-settings-content {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  input {
    background-color: transparent;
    color: $gray500;
    border-radius: $borderRadiusSm;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    max-width: 400px;
    height: 40px;
    border: 1px solid $gray300;

    &:focus {
      outline: none;
    }
  }

  .container-page-settings {
    display: flex;

    @media (max-width: 767.95px) {
      flex-direction: column;
    }
  }

  .container-account {
    padding: 32px 110px;

    @media (max-width: 991.95px) {
      padding: 32px 0;
    }
  }

  .btn-setting-tab {
    border-radius: $borderRadiusSm;
    color: $gray400;

    &:hover {
      background: $gray100;
      color: $gray900;
    }
  }

  .setting-active {
    font-family: "Inter", sans-serif;
    font-size: 14px;

    &.active {
      color: $gray900;
      background: $gray100;
    }
  }

  h2 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h4 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h5 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #101828;
  }

  span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $gray500;
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    color: $gray500;
  }

  // plan-settings
  .plan-settings {
    border: 1px solid $gray300;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 24px;
    border-radius: 16px;
    flex-grow: 1;
    flex-basis: 0;

    .modal-pix {
      height: calc(100% - 150px) !important;
      max-height: 700px !important;
      padding: 40px !important;

      .button-modal-pix {
        border: 1px solid $gray300;
        &:hover {
          border: 1px solid $gray900;
        }
      }
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: $gray100;
        padding: 0 12px;
        border-radius: 8px;
        height: 40px;
      }
    }
  }

  // affiliation-settings
  .affiliation-settings {
    gap: 32px;
    span {
      color: $gray900;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        display: flex;
        align-items: center;
        gap: 8px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          background-color: #fff;
          border: 1px solid $gray300;
          border-radius: 6px;
          font-size: 10px;
        }
      }
    }
  }

  // support settings
  .support-settings {
    background: #f2f4f7;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    gap: 16px;
    border-radius: 16px;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 140px;
    min-width: 140px;
    min-height: 175px;

    @media (max-width: 567.92px) {
      max-width: 100%;
      min-width: 100%;
      justify-content: start;
      align-items: flex-start;
      min-height: auto;
      flex-direction: row;

      .details {
        align-items: start !important;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 100%;

      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        object-fit: cover;
      }

      p {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #667085;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
}
