.card-assistants-new-layout {
  background: #f2f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  flex-grow: 1;
  flex-basis: 0;

  &.vertical {
    height: 240px;
    flex-direction: column;
    p {
      text-align: center;
    }
    @media screen and (max-width: 567.92px) {
      height: 200px;
    }
  }

  &.horizontal {
    flex-direction: row;
    justify-content: center;

    .details {
      align-items: flex-start;
      width: 100%;
      max-width: 250px;
      justify-content: center;
    }
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 567.92px) {
      width: 60px;
      height: 60px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 100%;

    h2 {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #101828;

      @media screen and (max-width: 567.92px) {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #667085;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  .sizeIconLike{
    width: 16px !important;
    height: 16px !important;
  }
}
