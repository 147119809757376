@import "../../variables";

.solana-network {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 767.92px) {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header {
      p {
        font-size: 12px;
        text-transform: uppercase;
        color: $gray700;
        margin-bottom: 15px;
      }
  
      h1 {
        font-size: 32px;
        font-weight: 600;
        color: $gray900;
        margin: 0;
      }
    }
  
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          font-size: 1rem;
          color: #333;
          margin-bottom: 0.5rem;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
  
    }
    .graphics {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (max-width: 767.92px) {
       gap: 16px;
      }

      .graphic {
        width: 229px;
        height: 50px;
        border-radius: 6px;

        @media screen and (max-width: 767.92px) {
          height: 40px;
          width: 190px;
        }

        &.green {
          background-color: #0DC595;
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        }

        &.blue {
          background-color: #32A4B2;
          clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%);
        }

        &.purple {
          background-color: #944AFB;
          clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        }
      }
    }
  }
  