//
// chat-leftsidebar.scss
//

.chat-leftsidebar {
  height: calc(100vh - 60px);
  position: relative;
  background-color: $sidebar-sub-bg;
  box-shadow: $box-shadow;

  @media (min-width: 992px) {
    min-width: 300px;
    max-width: 300px;
    height: 100vh;

    &.small {
      min-width: 90px;
      max-width: 90px;
    }

    &.full {
      min-width: calc(100vw - 67px);
      max-width: calc(100vw - 67px);
      height: 100vh;
    }
  }

  .user-status-box {
    background-color: $light;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    margin-top: 16px;
    display: block;

    .chat-user-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }

  .search-icon-btn {
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 0;
  }
}

.chat-room-list {
  // max-height: calc(100vh - 130px);
  max-height: calc(100vh);
}

.chat-group-list {
  // height: calc(100vh - 140px);
  max-height: calc(100vh);

  @media (max-width: 991.98px) {
    height: calc(100vh - 64px);
  }
}

.chat-list {
  margin: 0;

  div.active // & > div:hover

    {
    background-color: rgba(#1c1e23, 0.2);
  }

  li {
    &.active {
      a {
        background-color: rgba(#1c1e23, 0.2);

        span.avatar-title {
          color: $white !important;

          &.bg-light {
            background-color: rgba($light, 0.25) !important;
          }
        }

        .badge {
          background-color: rgba($light, 0.25) !important;
          color: $white !important;
        }
      }
    }

    a {
      display: block;
      max-width: 12rem;
      padding: 5px 10px;
      color: lighten($gray-700, 7.5%);
      transition: all 0.4s;
      font-size: 14px;
    }

    .chat-user-message {
      font-size: 14px;
    }

    .unread-msg-user {
      font-weight: 600;
    }

    &.typing {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 24px
        /*rtl:auto*/
      ;
      left: auto
        /*rtl:0*/
      ;
      top: 33px;

      .badge {
        line-height: 16px;
        font-weight: $font-weight-semibold;
        font-size: 10px;
      }
    }
  }
}

.chat-item {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  background: #1b323a08;
  border-radius: 6px !important;

  &:hover {
    background-color: $chat-hover-bg;
  }
}

.button-leave-chat {
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 12px !important;
  transition: all 0.15s;

  &:hover {
    filter: saturate(1.5);
  }
}

.button-leave-chat-header {
  transition: all 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.chat-user-img {
  position: relative;

  .user-status {
    width: 10px;
    height: 10px;
    background-color: $gray-500;
    border-radius: 50%;
    border: 2px solid $card-bg;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
  }

  &.online {
    .user-status {
      background-color: $success;
    }
  }

  &.away {
    .user-status {
      background-color: $warning;
    }
  }
}

// contact list

.contact-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1441px) {
    grid-template-columns: repeat(4, 1fr);
  }

  li {
    cursor: pointer;
    width: 100%;
  }

  &-activated {
    padding-top: 6px;
    border-radius: 4px;
    border: 1px solid rgba(#1c1e23, 1);
    color: $white;
    background: rgba(#1c1e23, 0.15);
  }
}

.contact-list-title {
  padding: 6px 0;
  color: rgba(#1c1e23, 1);
  font-weight: $font-weight-medium;
  position: relative;
  font-size: 12px;

  &:after {
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    right: 0;
    background-color: $border-color;
  }
}

// Calls

.chat-call-list,
.chat-bookmark-list {
  max-height: calc(100vh - 68px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 125px);
  }

  li {
    position: relative;
    padding: 10px 24px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }
  }
}

// setting

.profile-user {
  position: relative;
  display: inline-block;

  .profile-photo-edit {
    position: absolute;
    right: 0
      /*rtl:auto*/
    ;
    left: auto
      /*rtl:0*/
    ;
    bottom: 0;
    cursor: pointer;
  }

  .user-profile-img {
    object-fit: cover;
  }

  .profile-img-file-input {
    display: none;
  }
}

.theme-btn-list {
  &.theme-color-list {
    .form-check .form-check-input:checked+.form-check-label::before {
      color: $white;
    }
  }

  .form-check {
    padding: 0;

    .form-check-input {
      display: none;
    }

    .form-check-label {
      position: relative;
    }

    .form-check-input:checked+.form-check-label {
      &::before {
        content: "\e9a4";
        font-family: boxicons !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: $primary;
        font-size: 16px;
      }
    }
  }

  &.theme-btn-list-img {
    .theme-btn {
      background-color: transparent !important;
    }
  }
}

.theme-btn {
  cursor: pointer;
  border: 1px solid $gray-400;
}

// body[data-layout-mode="dark"] {
//   .chat-leftsidebar {
//     background-color: darken($gray-dark-300, 3%);
//   }

//   .chat-list {
//     li {
//       a {
//         color: $gray-dark-600;
//       }
//     }
//   }

//   .chat-user-img .user-status {
//     border-color: $gray-dark-200;
//   }

//   .contact-list-title:after {
//     background-color: $gray-dark-300;
//   }

//   .theme-btn {
//     border-color: $gray-dark-300;
//   }

//   // Calls

//   .chat-call-list,
//   .chat-bookmark-list {
//     li {
//       border-bottom: 1px solid $gray-dark-300;
//     }
//   }
// }