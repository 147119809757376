.modal-loading-transfer-metamask{
  backdrop-filter: blur(10px);
}

.loading {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #17152a;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

