.brand-assets {
  h1 {
    color: #3bcdbc;
    font-size: 3.5rem;
    font-weight: 700;
    @media (max-width: 991.98px) {
        font-size: 3rem;
    }
    @media (max-width: 766.98px) {
        font-size: 2.5rem;
    }
  }
  .brand-assets-logo-icon {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    margin: 50px;
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .banner-black{
    position: relative;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        margin-left: -13vw;
        background: black;
        z-index: 5;
    }
  }

  .grid-brand-colors{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;

    @media (max-width: 991.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 566.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    h3{
        font-size: 13px;
        font-weight: 700;
        margin: 0;
    }
    p{
        font-size: 13px;
    }

    > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 150px 10px;
        width: 100%;
    }
  }
}
