@import "../../variables";

.side-menu-type-2 {
  width: 270px;
  height: 100vh;
  overflow: auto;
  background-color: #ffffff;
  padding: $paddingSm;
  display: flex;
  gap: $gapMd;
  flex-direction: column;
  transition: all 0.3s;

  @media (max-width: 991.92px) {
    height: 100%;
    overflow: visible;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  @media (max-width: 991.92px) {
    padding: 7px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .dropdown-chats {
    position: fixed;
    top: 245px;
    left: 225px;
    width: 250px;
    height: 48px;
    z-index: 10000;
    border: 1px solid $gray200;
    box-shadow: 0px 1.63px 6.52px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background: #ffffff;

    &:hover {
      background: $gray100;
      color: $gray900;
    }

    @media (max-width: 576.92px) {
      left: 100px;
    }
  }

  .button-search-aside {
    width: 24px;
    height: 24px;
    &:hover {
      background: $gray100;
      #search-chats::placeholder {
        color: $gray700;
      }
      .icon-search {
        color: $gray700;
      }
    }
    input {
      width: 100%;
    }

    .icon-search,
    #search-chats::placeholder {
      color: $gray500;
    }
  }

  .btn-open-bar {
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .icon-open-bar {
      svg {
        color: $gray500;
      }
      &:hover {
        background: $gray100;
        svg {
          color: $gray800;
        }
      }
    }

    @media (max-width: 992px) {
      min-height: 36px;
      width: 36px;
      img {
        height: 18px;
      }
    }
  }

  &.minimize {
    width: 60px;
    background-color: #fcfcfd;
    border-right: 1px solid rgba($gray300, 0.4);

    .btn-open-bar {
      border: 1px solid $gray300;
      width: 100%;
      .icon-open-bar {
        svg {
          width: 20px !important;
          height: 20px !important;
        }
        &:hover {
          background-color: transparent;
          svg {
            color: $gray800;
          }
        }
      }
    }

    .divider {
      border: 1px solid rgba($gray300, 0.6);
      height: 1px;
    }

    .footer-aside-nav {
      button {
        background: none;
        border: 1px solid $gray300;
        color: $gray500;
        justify-content: center !important;

        &:hover {
          color: $gray800;
        }
      }
    }

    @media (max-width: 992px) {
      width: 50px;
      padding: 7px;
    }
  }

  .group-list-chats {
    margin-top: 10px;

    .hover-list-chats{
      cursor: pointer;

      &:hover {
        background-color: $gray100;
      }
    }

    h6 {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 10px;
      color: $gray500;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .footer-aside-nav {
    display: flex;
    flex-direction: column;
    gap: $gapSm;
    margin-top: auto;

    button {
      color: $gray500;
    }

    .divider {
      border: 1px solid rgba($gray300, 0.6);
    }
  }

  .list-chats {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
