//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

[disabled]{
  pointer-events: none;
  opacity: .6;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

.btn-primary {
  background-color: rgba(#1c1e23, 0.9);
  border-color: rgba(#1c1e23, 0.9);

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(#1c1e23, 1);
    border-color: rgba(#1c1e23, 1);
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(#1c1e23, 1);
  }
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.btn-soft-primary {
  color: rgba(#1c1e23, 1);
  background-color: rgba(#1c1e23, 0.1);

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: rgba(#1c1e23, 1);
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(#1c1e23, 0.5);
  }
}

// Soft Light button
.btn-soft-light {
  color: $gray-600;

  &:hover,
  &:focus,
  &:active {
    color: $gray-800;
  }
}

// body[data-layout-mode="dark"] {
//   // buttons
//   .btn-primary {
//     color: $gray-dark-200 !important;
//   }
//   .btn-light {
//     color: $gray-dark-600;
//     background-color: $gray-dark-300;
//     border-color: $gray-dark-300 !important;
//   }
// }

.button-sign-wallet-metamask {
  background: white;
  color: #adb5bd;
  width: 100%;
  height: 45px;
  border-width: 1px;
  border-radius: 4px !important;
  border-color: #808080 !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 10px;
  }

  span {
    color: #212034 !important;
    font-weight: 450;
  }
}
