//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }

  &[data-popper-placement="top-start"] {
    animation-name: DropDownSlideDown;
  }
}

@keyframes DropDownSlide {
  100% {
    margin-top: -1px;
  }
  0% {
    margin-top: 8px;
  }
}

@keyframes DropDownSlideDown {
  100% {
    margin-bottom: 0;
  }
  0% {
    margin-bottom: 8px;
  }
}

@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }

  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}
.dropdown-mega-menu-xl {
  width: 32rem;
}

// body[data-layout-mode="dark"] {
//   // Dropdown
//   .dropdown-menu {
//     background-color: lighten($gray-dark-300, 2%);
//     border-color: lighten($gray-dark-300, 4%);
//     color: $gray-dark-500;
//     box-shadow: 0 5px 6px rgba($gray-dark-200, 0.1);
//   }

//   .dropdown-item {
//     color: $gray-dark-500;

//     &:hover,
//     &:active,
//     &:focus {
//       background-color: lighten($gray-dark-300, 4%);
//     }

//     &.active,
//     &:active {
//       background-color: lighten($gray-dark-300, 4%);
//     }
//   }

//   .dropdown-divider {
//     border-top-color: lighten($gray-dark-300, 4%);
//   }
// }

.dot {
  width: 10px;
  height: 10px;
  background-color: #13af9d;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

// dropdown-menu-account
.dropdown-menu-account {
  width: 345px;
  border-radius: 16px;
  box-shadow: none;
  border: 1px solid $gray200;

  @media (max-width: 567px) {
    width: 100vw;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #e8e8e8;
  }

  span {
    color: $greenSucess;
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 0;
  }

  .dropdown-item:hover {
    background: $gray100 !important;
    color: $gray900;
  }

  button {
    background: $gray100;
    height: auto !important;
    border-radius: 8px;
    padding: 8px 12px !important;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: $gray900;

    i {
      margin-right: 12px;
    }
  }
}

// dropdown settings mobile
.settings-chat-mobile {
  width: 250px;
  border-radius: 12px;
  left: -20;
  top: 50px;

  @media (max-width: 567px) {
    border-top: 1px solid #e8e8e8;
  }

  h4 {
    color: $gray500;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    margin: 0;
  }

  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    background: $gray100 !important;
  }
}

.dropdown-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  .dropdown-header {
    position: relative;
    padding: 8px 16px;
    background-color: $gray100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid $gray200;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.134);
    font-size: 12px;
    z-index: 999;

    @media (max-width: 991.95px) {
      top: 220px ;
      right: auto;
    }

    .dropdown-item {
      padding: 10px;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
