// _button-default-account.scss

.button-default-account {
  background-color: $gray100;
  color: $gray500;
  border: none;
  border-radius: $borderRadiusSm;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0 16px;
  height: 40px;

  svg{
    width: 16px;
    height: 16px;
  }

  img{
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  button{
    border: none;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
  }

  input {
    border: none;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    font-size: 16px;

    &:focus{
        outline: none;
    }
  }

  &.border {
    background-color: transparent;
    border: 1px solid $gray300;
  }
}
