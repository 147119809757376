.nav-mobile-home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #ffffff;
  padding: 20px 0;
  animation: slide-down 1s forwards;
  display: flex;
  justify-content: center;

  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  nav {
    display: flex;
    flex-direction: column;

    a {
      font-size: 1.125rem;
      display: flex;
      justify-content: center;
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
