@import "../../variables";

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-box {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  margin-bottom: 4px;
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: $gray900;
  color: #fff;
  white-space: normal;
  font-size: 12px;
  font-weight: 500;
  z-index: 100001;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
  width: max-content;
  max-width: 350px;
}

.tooltip-box.show {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}

.tooltip-arrow {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #101828;
}
