@import "../../variables";

.account-group {
  margin-left: auto;

  .box-account {
    border: 1px solid #d0d5dd;
    border-radius: $borderRadiusSm;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 40px;
    color: $gray500;
    width: 180px;

    @media (max-width: 567.92px) {
      width: 145px;
      height: 32px;
    }

    > span {
      max-width: 123px;
      font-weight: 500;
    }
    &:hover {
      span {
        color: $gray700;
      }
      svg {
        color: $gray700;
      }
    }
  }

  .btn-publish {
    height: 40px;

    @media (max-width: 567.92px) {
      height: 32px;
    }
  }

  .btn-login {
    max-height: 40px;
    min-height: 40px;
    font-size: 15px;
    border-radius: $borderRadiusSmPlus;
    padding: 0 16px;

    @media (max-width: 567.92px) {
      max-height: 32px;
      min-height: 32px;
      border-radius: $borderRadiusSm;
    }
  }
}
