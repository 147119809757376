@import "../../variables";

.modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);

  .modal-content-global {
    display: flex;
    justify-content: center;
    padding: 50px;
    width: 600px;
    background-color: #fff;
    border-radius: $borderRadiusMd;
    overflow: auto;
    position: relative;

    @media (max-width: 575.95px) {
      border-radius: 0;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
}

#main-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

#modal-rename-channel {
  .modal-content {
    border: 1px solid $gray200;
    padding: 24px;
    border-radius: 16px;

    .modal-title-custom {
      border: 0;
      h5.modal-title {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: $grayType5;
      }
      button.btn-close {
        width: 20px;
        height: 20px;
        padding: 8px;
        border-radius: 6px;
        font-size: 16px;
        color: $grayType3;
        display: flex;
        align-self: center;
        justify-content: center;
        background-color: #f2f4f7;
        &:hover {
          color: $grayType1;
        }
      }
    }
    .modal-body {
      label {
        color: $grayType5;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
      input {
        width: 100%;
        height: 40px;
        padding: 0 16px;
        border-radius: 8px;
      }
    }
    .modal-footer {
      border: 0;
      button {
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 10px;

        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
      button.btn-link {
        border: 1px solid $gray100;
        color: $gray500;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
          color: $grayType5;
        }
      }
      button.btn-primary {
        border: 1px solid $grayType5;
        background-color: $grayType5;
        transition: all 0.3s ease;
        color: white;
      }
    }
  }
}

.modal-attachment {
  width: 520px !important;
  p,
  u,
  h3,
  h4,
  label {
    margin: 0;
  }

  .name-document {
    @media (max-width: 567.92px) {
      .tooltip-box {
        left: 100%;
        .tooltip-arrow {
          left: 20%;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: $gray900;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $gray500;
  }

  .btn-file {
    background: #fff !important;
    border-radius: 8px;
    height: 32px;
    width: auto;
    min-width: 100px;
    color: $gray700;
    padding: 0 10px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      height: 40px;
      min-height: 40px;
      border-radius: 8px;

      .delete-attachment {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.modal-payment {
  width: calc(100% - 32px) !important;
  max-width: 1400px !important;
  height: 90%;
  max-height: 950px !important;
  margin-top: 60px;

  @media (max-width: 991.92px) {
    width: 100% !important;
  }

  .card-payment-method{
    &:hover {
      border: 1px solid $gray400 !important;
    }
  }

  ul {
    p,
    li {
      color: $gray800 !important;
    }
  }

  h1 {
    font-size: 30px !important;
    margin: 0;

    @media (max-width: 567.92px) {
      font-size: 22px !important;
    }
  }

  h4 {
    font-weight: 500 !important;
    margin: 0;
  }

  h5 {
    margin: 0;
    font-size: 18px !important;
    color: $gray700 !important;

    @media (max-width: 567.92px) {
      font-size: 16px !important;
    }
  }

  .btn-icon-header {
    background: $gray100;
    border-radius: $borderRadiusSm;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      svg {
        color: $gray700;
      }
    }

    svg {
      color: $gray500;
    }
  }
}
