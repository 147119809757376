// :root CSS variables

:root {
  // Vertical Sidebar - Default Light

  --#{$variable-prefix}sidebar-bg: #212034;
  --#{$variable-prefix}sidebar-menu-item-color: #878a92;
  --#{$variable-prefix}sidebar-menu-item-active-color: #4eac6d;
  --#{$variable-prefix}sidebar-menu-item-active-bg: #f7f7ff;
  --#{$variable-prefix}sidebar-sub-bg: #ffffff;

  // footer
  --#{$variable-prefix}footer-bg: rgba(255, 255, 255, 0.05);

  // Display
  --#{$variable-prefix}display-none: none;
  --#{$variable-prefix}display-block: block;

  // Chat
  --#{$variable-prefix}chat-text-color: #495057;
  --#{$variable-prefix}chat-primary-bg: #ffffff;

  // component variable

  // theme-color
  --#{$variable-prefix}light: #{$gray-300};
  --#{$variable-prefix}light-rgb: #{to-rgb($gray-300)};
  --#{$variable-prefix}dark: #{$gray-900};
  --#{$variable-prefix}dark-rgb: #{to-rgb($gray-900)};
  --#{$variable-prefix}text-muted: #{$gray-600};

  // link
  --#{$variable-prefix}link-color: #{$primary};
  --#{$variable-prefix}link-hover-color: #{$primary};

  // Border variable
  --#{$variable-prefix}border-color: #eaeaf1;

  // dropdown
  --#{$variable-prefix}dropdown-bg: #{$white};
  --#{$variable-prefix}dropdown-border-color: #f6f6f9;
  --#{$variable-prefix}dropdown-link-color: #{$gray-900};
  --#{$variable-prefix}dropdown-link-hover-color: #{shade-color($gray-900, 5%)};
  --#{$variable-prefix}dropdown-link-hover-bg: #{$gray-100};
  --#{$variable-prefix}dropdown-border-width: 1px;

  // card
  --#{$variable-prefix}card-bg: #{$white};
  --#{$variable-prefix}card-cap-bg: #{$white};
  --#{$variable-prefix}card-border-color: #eff0f2;
  --#{$variable-prefix}card-logo-dark: block;
  --#{$variable-prefix}card-logo-light: none;
  --#{$variable-prefix}card-box-shadow: 0 2px 3px #e6e8eb;

  // modal
  --#{$variable-prefix}modal-bg: #{$white};
  --#{$variable-prefix}modal-content-bg: #{$white};
  --#{$variable-prefix}modal-content-border-color: #{$gray-300};

  // nav tabs
  --#{$variable-prefix}nav-tabs-link-active-color: #{$gray-700};
  --#{$variable-prefix}nav-tabs-link-active-bg: #{$body-bg};

  // accordion
  --#{$variable-prefix}accordion-button-active-color: #{shade-color(
      $primary,
      10%
    )};
  --#{$variable-prefix}accordion-bg: #{$white};
  --#{$variable-prefix}accordion-button-bg: #{$gray-200};
  --#{$variable-prefix}accordion-button-active-bg: rgba(246, 246, 249, 0.7);

  // table
  --#{$variable-prefix}table-color: #{$body-color};

  // Boxed layout
  --#{$variable-prefix}boxed-body-bg: #e9ebf0;

  // body
  --#{$variable-prefix}body-heading-color: #{$gray-700};
  --#{$variable-prefix}body-bg: #ffffff;
  --#{$variable-prefix}body-color: #{$gray-700};
  --#{$variable-prefix}body-color-rgb: #{to-rgb($body-color)};

  // progress
  --#{$variable-prefix}progress-bg: #{$gray-200};

  // toast
  --#{$variable-prefix}toast-background-color: #{rgba($white, 0.85)};
  --#{$variable-prefix}toast-border-color: #{rgba($black, 0.1)};
  --#{$variable-prefix}toast-header-border-color: #{rgba($black, 0.05)};

  //list
  --#{$variable-prefix}list-group-hover-bg: #{$gray-100};

  // popover
  --#{$variable-prefix}popover-bg: #{$white};

  // pagination
  --#{$variable-prefix}pagination-hover-bg: #{$gray-200};

  // form
  --#{$variable-prefix}input-bg: #{$white};
  --#{$variable-prefix}input-group-addon-border-color: #{$gray-400};
  --#{$variable-prefix}input-border: #{$gray-400};
  --#{$variable-prefix}input-border-color: #cfd4d8;
  --#{$variable-prefix}input-focus-border: #{tint-color(
      $component-active-bg,
      50%
    )};
  --#{$variable-prefix}input-disabled-bg: #{$gray-200};

  // input
  --#{$variable-prefix}input-placeholder-color: #{$gray-600};
  --#{$variable-prefix}input-group-addon-bg: #{$gray-200};

  // Breadcrumbs

  //check
  --#{$variable-prefix}input-check-border: var(
    --#{$variable-prefix}input-border
  );
}
