//
// Sidemenu
//

.side-menu {
  min-width: 75px;
  max-width: 75px;
  height: 100vh;
  min-height: 570px;
  display: flex;
  z-index: 9;
  padding: 0 8px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 991.98px) {
    position: fixed;
    bottom: 0;
    height: 60px;
    min-width: 100%;
    min-height: auto;
  }

  .navbar-brand-box {
    text-align: center;

    svg {
      fill: rgba(#1c1e23, 1);
      max-width: 50px;
    }

    .logo-dark {
      display: $display-block;
    }

    .logo-light {
      display: $display-none;
    }
  }
}

.sidemenu-navigation {
  height: 100%;

  @media (max-width: 991.98px) {
    display: none !important;
  }
}

.side-menu-nav {
  height: 100%;
  flex-direction: column;

  @media (max-width: 991.98px) {
    justify-content: space-between !important;
    flex-direction: row;
    width: 100%;
  }

  .nav-item {
    margin: 7px 0;
    display: block;
    width: 100%;

    @media (max-width: 991.98px) {
      flex-basis: 0;
      flex-grow: 1;
      margin: 5px 0;
    }

    .nav-link {
      text-align: center;
      font-size: 28px;
      color: $sidebar-menu-item-color;
      width: 100%;
      height: 56px;
      line-height: 56px;
      padding: 0;
      position: relative;

      i {
        vertical-align: middle;

        @media (max-width: 991.98px) {
          font-size: 24px;
        }
      }

      @media (max-width: 991.98px) {
        font-size: 20px;
        width: 48px;
        height: 48px;
        line-height: 48px;
        margin: 0px auto;
      }

      &.active {
        background-color: transparent;
        color: rgba(#1c1e23, 1);

        &::before {
          background-color: rgba(#1c1e23, 1);
        }
      }
    }

    &.show > .nav-link {
      color: rgba(#1c1e23, 1);
    }
  }

  .profile-user {
    height: 36px;
    width: 36px;
    padding: 6px;
    background: none !important;
    border-radius: 100%;
    transition: all 0.15s;

    &:hover {
      transform: translateY(-3px);
    }
  }
}

.side-menu-core {
  transition: all 0.15s;

  &:hover {
    transform: translateY(-3px);
    color: $primary;
  }
}

.side-menu-core-premium {
  transition: all 0.15s;

  &:hover {
    transform: translateY(-3px);
    color: #f2a005;
  }
}

.active-premium {
  color: #f2a005 !important;
}

.light-mode {
  display: none;
}

// body[data-layout-mode="dark"] {
//   .side-menu-nav {
//     .nav-item {
//       .nav-link {
//         color: $gray-dark-500;

//         &.light-dark {
//           .bx-moon {
//             &:before {
//               content: "\eb90";
//             }
//           }
//         }

//         &.active {
//           color: rgba(#1c1e23, 1) !important;
//         }
//         &.active-premium {
//           color: #f2a005 !important;
//         }
//       }
//     }
//   }

//   .light-mode {
//     display: inline-block;
//   }

//   .dark-mode {
//     display: none;
//   }
// }

.sidemenu-navigation-mobile {
  display: none;

  @media (max-width: 991.98px) {
    display: flex !important;
  }
}
