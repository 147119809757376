body {
}

.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;

  .status {
    position: "absolute";
    left: "50%";
    top: "50%";
    transform: "translate(-50%; -50%)";

    .sr-only {
      display: none !important;
    }
  }
}

.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }

  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;

    span {
      display: none !important;
    }
  }
}

.alert-dismiss-custom {
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;

    span {
      display: none !important;
    }

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.card-assistants {
  transition: all 0.3s;
  opacity: 0;

  cursor: pointer;

  &:hover {
    filter: saturate(1.4);
  }

  img {
    transition: all 0.3s;
  }
}

.card-assistants-loaded {
  transition: opacity 1s;
  opacity: 1;
}

.flag-button {
  transition: all 0.15s;

  &:hover {
    transform: translateY(-3px);
  }

  @media (max-width: 991.98px) {
    margin-bottom: 12px;
  }
}

.button-theme {
  &:hover {
    animation: spin 4s infinite linear;
  }
}

.heartBeat {
  animation: heartBeatAnimation 0.5s forwards;
}

.button-close-documents {
  position: absolute;
  top: 16px;
  right: 16px;
}

@keyframes heartBeatAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// [data-layout-mode="light"] .button-theme {
//   &:hover {
//     animation: pulse 1.5s infinite;
//     transform-origin: center;
//   }
// }

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.user-chat-topbar .topbar-bookmark .close {
  padding: 12px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.button-details-chat {
  color: $gray-600;
  transition: color 0.3s;

  &:hover {
    color: $gray-400;
  }
}

.open-journey {
  margin-right: 1rem;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 8px;
}

.button-create-image {
  margin-right: 1rem;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 8px;

  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: saturate(1.5);
  }
}

.web3-button {
  text-indent: -999em !important;
  transition: all 0.3s;

  &:hover {
    filter: saturate(1.5);
  }

  &:after {
    content: "Create NFT";
    text-indent: 0 !important;
  }
}

.button-hover {
  transition: background-color 0.3s;

  &:hover {
    background-color: $chat-hover-bg;
  }
}

.icon-default-light {
  color: #ffffff;
}

.icon-default-dark {
  color: #e6ebf5;
}

.button-active {
  background-color: $chat-hover-bg;
  transition: background-color 0.3s;

  &:hover {
    background-color: $primary;
  }
}

.PhoneInputInput {
  padding: 8px 10px;
  border-radius: 0.2em;
  border: 1px solid #121214;
  background-color: #e9eaee;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// body[data-layout-mode="dark"] .PhoneInputInput {
//   background-color: #0f0f1cd0;
//   color: #e1e1e6;
//   border: 0 !important;
//   padding: 8px 10px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }

@media (max-width: 820px) {
  .open-journey,
  .button-create-image {
    text-align: center;
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }
}

@media (max-width: 400px) {
  .open-journey {
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    white-space: nowrap;
  }
}

.hit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin: 10px 0;
  border-radius: 5px;
}

.hit-image {
  max-width: 200px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 5px;
}

.hit-heading {
  margin: 10px 0;
}

.hit-subheading {
  margin: 8px 0;
  color: #555;
}

.hit-text {
  text-align: center;
}
