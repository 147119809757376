@import "../../variables";

.group-main-login {
  width: 470px;
  max-width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 1024;
  top: 0;
  display: flex;
  max-height: 100%;
  overflow: auto;
  flex-direction: column;
  right: 0;
  padding: $paddingXL;
  border-radius: $borderRadiusMd 0 0 $borderRadiusMd;
  box-shadow: 0 0 25px #00000028;

  .btn-close-login {
    &:hover {
      svg {
        color: $gray700;
      }
    }
    svg {
      color: $gray500;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }

  .header {
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin-bottom: 0;
      }

      .switch-web-mode {
        display: flex;
        align-items: center;
        border-radius: $borderRadiusSm;
        padding: 3px;
        gap: 3px;
        background-color: $gray100;

        button {
          padding: 5px 10px;
          font-size: 13px;
          border-radius: $borderRadiusSm - 1 !important;

          &.active,
          &:hover {
            background-color: #fff;
          }
        }
      }
    }

    > p {
      opacity: 0.7;
      font-size: 14px;

      button {
        text-decoration: underline;
        margin-left: 7px;
      }
    }
  }

  .form-login-web2 {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .btn-main-form,
    .btn-socials {
      border-radius: $borderRadiusSm !important;
      padding: 13px 20px;
    }

    .divider {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0;
      opacity: 0.5;
      color: #000;
      font-size: 14px;

      &::before,
      &::after {
        content: "";
        height: 1px;
        flex-grow: 1;
        flex-basis: 0;
        background-color: #000;
        opacity: 0.18;
        max-width: 100%;
      }
    }
  }

  .text-terms {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color : $gray500;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
}
