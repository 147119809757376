@import "../../variables";

button {
  border: none;
  background: none;
  padding: 0;
  transition: all 0.2s;
  font-size: 14px;
  color: #000;
}

.hover-tooltip-button {
  background-color: $gray100;
  &:hover {
    background-color: rgb(232, 231, 231);
    transition: all 0.25s;
  }
}

.button-aside-type-1 {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 12px 15px;
  font-size: 15px;
  text-align: left;
  border-radius: $borderRadiusSm !important;
  transition: all 0.3s;
  height: 40px;
  color: $gray900;

  @media (max-width: 992px) {
    padding: 10px 8px;
    font-size: 12px;
  }

  &.hover:hover {
    color: $gray800;
  }

  &.dark {
    background-color: $gray900;
    color: #fcfcfd;

    &:hover {
      background-color: $gray800;
    }
  }
}

.button-primary {
  background-color: $gray500;
  color: #ffffff;
  font-size: 14px;
  background-color: $gray900;

  &:hover {
    background-color: $gray700;
  }
}

.button-secondary {
  background-color: #fcfcfd;
  color: $gray500;

  &:hover {
    color: $gray900;
  }
}

.button-thirty {
  background-color: $gray100;
  color: #000;

  &:hover {
    background-color: $gray800;
    color: #fcfcfd;

    img {
      color: #fcfcfc;
    }
  }
}
