@import "../../variables";

.token-distribution-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #000;
  }
.content-title-and-dot{
  justify-content: space-between;
  @media screen and (max-width: 567.92px) {
    max-width: 160px;
  }
    .circle {
      width: 180px;
      height: 180px;
      position: relative;

      @media screen and (max-width: 991.92px) {
        width: 120px;
        height: 120px;
      }

      .dot-page-token {
        width: 16px;
        height: 16px;
        background-color: $gray900;
        border-radius: 50%;
        position: absolute;
        bottom: 35px;
        right: 35px;
      }
  }
}

.content {
    display: flex;
    gap: 20px;
    align-items: flex-start;


    .distribution-info {
      color: $gray700;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          gap: 10px;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          .locked-dot,
          .unlocked-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          .locked-dot {
            background-color: #ccc;
          }

          .unlocked-dot {
            background-color: $gray900;
          }
        }
      }

      hr {
        border: none;
        border-top: 1px solid #ddd;
        margin: 24px 0;
      }
    }
  }
}
