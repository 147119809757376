@import "../../variables";

.float-button {
  position: fixed;
  z-index: 1024;
  height: 40px;
  width: 40px;
  top: 300px;
  border-radius: $borderRadiusSm 0 0 $borderRadiusSm !important;
  box-shadow: 0 0 10px #00000070;
  align-self: end;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;

    span {
      width: 18px;
      height: 18px;
      font-size: 11px;
      border-radius: 50% !important;
      background-color: $colorType1;
      color: $gray900;
      position: absolute;
      bottom: -8px;
      box-shadow: 0 0 5px #00000070;
      left: -8px;
    }
  }
}

.section-attachment-files {
  background-color: $colorType1;
  width: 100%;
  padding: $paddingMd;
  border-radius: $borderRadiusMd;
  overflow: auto;
  max-height: 100%;

  &.fixed-section {
    position: fixed;
    z-index: 1024;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px !important;
    padding: $paddingLg;
    border-radius: $borderRadiusMd 0 0 $borderRadiusMd;
    box-shadow: 0 0 25px #00000028;
  }

  h6 {
    margin-bottom: 15px;
  }

  .list-attachment-files {
    display: flex;
    flex-direction: column;
    gap: $gapSm;
  }
}

.card-attachment-file .delete-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-attachment-file svg{
  min-width: 20px;
  min-height: 20px;
}

.card-attachment-file:hover .delete-button {
  opacity: 1;
}
