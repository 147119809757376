@import "../../variables";

.skeleton-loader {
  width: 100%;
  height: 56px;
  border-radius: 16px;
  background-color: $gray100;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}

.retangle {
  width: 120px;

  @media (max-width: 768.98px) {
    width: 80px;
  }

  &:nth-child(2) {
    width: 48px;
    @media (max-width: 768.98px) {
      width: 32px;
    }
  }

  &:nth-child(4) {
    width: 80px;
    @media (max-width: 768.98px) {
      width: 53px;
    }
  }

  span{
    border-radius: 16px; 
  }
}