$sizeBox: 45px;
$sizeIcon: $sizeBox - 16px;

.group_assistants_home {
  display: flex;
  flex-wrap: wrap;

  >.box_text {
    width: 100%;
    max-width: 350px;
    display: flex;
    max-height: 500px;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 992px) {
      order: 2;
      max-width: 100%;
      margin-top: 50px;

      h2 {
        text-align: center;
      }
    }
  }

  .list-cards {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    @media (max-width: 992px) {
      flex-grow: unset;
      flex-basis: unset;
      width: 100%;
      order: 1;
    }

    @media (min-width: 1200px) {
      margin-left: 100px !important;
    }
  }
}

.card-main {
  width: 50%;

  @media (max-width: 576px) {
    width: min(100%, 380px);
  }

  @media (max-width: 576px) {
    &:nth-last-child(1) {
      .card_action {
        padding-bottom: 0 !important;

        &::before {
          display: none !important;
        }
      }
    }
  }

  @media (min-width: 576px) {
    &:nth-last-child(-n + 2) {
      .card_action {
        padding-bottom: 0 !important;

        &::before {
          display: none !important;
        }
      }
    }
  }

  .card_action {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    padding-bottom: 70px;

    &::before {
      content: "";
      position: absolute;
      left: calc($sizeBox / 2 + 10px);
      top: calc($sizeBox + 15px);
      width: 1px;
      background-color: #1c1e23;
      opacity: .3;
      height: calc(100% - $sizeBox - 25px);
    }

    &:hover{
      .icon{
        background-color: #3d3e44;
      }
    }

    .icon {
      background-color: #1c1e23;
      height: $sizeBox;
      border-radius: 14px 14px 3px 14px;
      margin-right: 15px;
      width: $sizeBox;
      display: flex;
      align-items: center;
      justify-content: center;

      i,
      svg {
        height: $sizeIcon;
        width: $sizeIcon;
        padding: 0 !important;
        font-size: $sizeIcon !important;
      }
    }


    .text {
      flex-grow: 1;
      flex-basis: 0;
      max-width: calc(100% - 90px);

      h6 {
        font-weight: 600;
      }
    }
  }
}