.modal-children-info-assistants {
  height: calc(100% - 80px);
  width: 600px !important;
  overflow: hidden !important;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;

    @media screen and (max-width: 567.92px) {
      width: 90px;
      height: 90px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: center;
    color: $gray900;
  }

  h4 {
    font-size: 16px;
    font-weight: 530;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $gray900;
  }

  .sizeIconLike {
    width: 20px !important;
    height: 20px  !important;
  }
}
