.side-menu-mobile {
  position: fixed;
  width: 70px;
  height: calc(100% - 60px);
  top: 0;
  left: 0;
  background: #fafafa;
  // box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);

  .side-menu-nav-mobile {
    height: 100%;
    flex-direction: column;
    gap: 12px;

    .dropdown-class-name {
      margin: 0 !important;
      display: flex !important;
      justify-content: center;
      width: 100%;
    }

    .nav-item {
      margin: 7px 0;
      display: flex;
      justify-content: center;
      width: 100%;

      .nav-link {
        text-align: center;
        font-size: 28px;
        color: $sidebar-menu-item-color;
        width: 100%;
        height: 56px;
        line-height: 56px;
        padding: 0;
        position: relative;

        i {
          vertical-align: middle;

          @media (max-width: 991.98px) {
            font-size: 24px;
          }
        }

        @media (max-width: 991.98px) {
          font-size: 20px;
          width: 48px;
          height: 48px;
          line-height: 48px;
        }

        &.active {
          background-color: transparent;
          color: rgba(#1c1e23, 1);

          &::before {
            background-color: rgba(#1c1e23, 1);
          }
        }
      }

      &.show > .nav-link {
        color: rgba(#1c1e23, 1);
      }
    }
  }
}

.light-mode {
  display: none;
}

body[data-layout-mode="light"] {
  .side-menu-mobile {
    background: white;
  }
}

// body[data-layout-mode="dark"] {
//   .side-menu-mobile {
//     .nav-item {
//       .nav-link {
//         color: $gray-dark-500;

//         &.light-dark {
//           .bx-moon {
//             &:before {
//               content: "\eb90";
//             }
//           }
//         }

//         &.active {
//           color: rgba(#1c1e23, 1) !important;
//         }
//         &.active-premium {
//           color: #f2a005 !important;
//         }
//       }
//     }
//   }

//   .light-mode {
//     display: inline-block;
//   }

//   .dark-mode {
//     display: none;
//   }
// }
