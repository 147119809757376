@media (max-width: 440px) {
  .conversation-user {
    max-width: 410px;
  }
}

.chat-conversation::-webkit-scrollbar {
  width: 3px;
}

.chat-conversation::-webkit-scrollbar-track {
  background: transparent;
}

.chat-conversation::-webkit-scrollbar-thumb {
  background: #8888882c;
}

.chat-conversation::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-conversation {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
